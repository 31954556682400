import { AgGridReact } from "ag-grid-react";
import React, { useRef, useState } from "react";
import { FileUploadHistoryDto } from "../common/upload.typings";
import { Badge, Button } from "@sgbs-ui/core";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import { downloadUploadFile, downloadUnknownClientsFile } from "../../../queries/upload";
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import { formatAmount } from "../../utils/numbers/numberUtils";

export interface Props {
  uploadHistory: FileUploadHistoryDto[];
}

export const UploadGrid: React.FC<Props> = ({ uploadHistory }) => {
  const [isShowingAll, setIsShowingAll] = useState<boolean>(false);
  const DEFAULT_PAGINATION_SIZE = 15;
  const gridRef = useRef<AgGridReact>(null);
  

  const downloadUnknownClientComponent = (node: { data: FileUploadHistoryDto }) => {
    if (node.data.hasUnknownClient){
      return <Button
        className="btn btn-sm btn-primary btn-icon-text"
        onClick={(e) => onExtractUnknownClients(node.data)}
        icon="download">
        Extract clients
      </Button>
    }
    return <></>
  };

  const downloadFileComponent = (node: { data: FileUploadHistoryDto }) => (
    <Button
      className="btn btn-sm btn-primary btn-icon-text"
      onClick={(e) => onDownload(node.data)}
      icon="download"
    >
      Download
    </Button>
  );

  const statusComponent = (params: any) => {
    switch (params.value) {
      case "success":
        return <Badge color="success" text={"Success"} />
      case "rejected":
        return <Badge color="danger" text={"Rejected"} />
      case "canceled":
        return <Badge color="warning" text={"Canceled"} />
      case "previousUpload":
        return <Badge color="secondary" text={"Previous Upload"} />
      default:
        return capitalize(params.value);
    }
  }

  const columns: ColDef<FileUploadHistoryDto>[] = [
    { field: "fileName", headerName: "File Name", flex: 1 },
    { field: "supplierMnemo", headerName: "Supplier Name", flex: 1 },
    { field: "uploadedDate", headerName: "Uploaded Date", flex: 1, valueFormatter: (date) => format(new Date(date.value), "yyyy-MM-dd HH:mm:ss") },
    { field: "accountingPeriod", headerName: "Accounting Period", flex: 1, valueFormatter: (params: any) => {
        const accountingPeriod = DateTime.fromFormat(params.value, "yyyyMM");
        return accountingPeriod.toFormat('MM/yyyy');
      }
    },
    { field: "checkStatus", headerName: "Status", flex: 1, cellRenderer: statusComponent },
    { field: "userName", headerName: "User Name", flex: 1 },
    { field: "totalNbi", headerName: "Total NBI", flex: 1, valueFormatter: (nbi) => formatAmount(nbi.value as number) },
    { field: "totalNbiUnknown", headerName: "NBI on unknown clients", flex: 1, valueFormatter: (unknownClientNbi) => formatAmount(unknownClientNbi.value as number) },
    { colId: "extractUnknownClient", headerName: "Extract unknown clients", flex: 1, cellRenderer: downloadUnknownClientComponent },
    { colId: "downloadFile", headerName: "Download file", flex: 1, cellRenderer: downloadFileComponent }
  ]

  const onExtractUnknownClients = (item: FileUploadHistoryDto) => {
    downloadUnknownClientsFile(item.id);
  };

  const onDownload = (item: FileUploadHistoryDto) => {
    downloadUploadFile(item.id);
  };

  const handleShowAllClick = () => {
    setIsShowingAll(!isShowingAll);
  }

  React.useEffect(() => {
    if (isShowingAll) {
      const rowCount = gridRef.current?.api.paginationGetRowCount();
      gridRef.current?.api?.setGridOption("paginationPageSize", rowCount);
    } else {
      gridRef.current?.api?.setGridOption("paginationPageSize", DEFAULT_PAGINATION_SIZE);
    }
  }, [isShowingAll])

  return (
    <div className="ag-theme-alpine">
      <AgGridReact
        ref={gridRef}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
        domLayout="autoHeight"
        rowData={uploadHistory}
        pagination={true}
        paginationPageSize={DEFAULT_PAGINATION_SIZE}
        onGridReady={() => { }}
        suppressHorizontalScroll={true}
        enableCellExpressions={true}
        tooltipShowDelay={1000}
        columnDefs={columns}
        paginationPageSizeSelector={[15, 25, 50]}
      />
      <div className="text-center mt-3 mb-3">
        <Button
          className="btn-lg btn-primary"
          text={isShowingAll ? "Hide rows" : "Show all rows"}
          onClick={handleShowAllClick}
        />
      </div>
    </div>
  );
}