import React, { useMemo } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { IntlProvider } from "../../context/IntlContext";
import { GlobalContext } from "../../context/GlobalContext";
import { Header, HelpCenter, Footer, PageNotFound } from "../common";
import { Home } from "../routes/Home/Home";
import "../../styles/main.scss";
import { ErrorCatch } from "../common/ErrorCatch";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { Authentication } from "../common/Authentication";

const App: React.FC = () => {
  /*   const matomo = createInstance({
    urlBase: process.env.REACT_APP_MATOMO_SERVER!,
    siteId: parseInt(process.env.REACT_APP_MATOMO_SITE_ID!, 10),
    trackerUrl: `${process.env.REACT_APP_MATOMO_SERVER}piwik.php`,
    srcUrl: `${process.env.REACT_APP_MATOMO_SERVER}piwik.js`,
  }); */
  const context = useMemo(() => ({}), []);
  const queryClient = new QueryClient();

  return (
    <ErrorCatch>
      <ToastContainer
        hideProgressBar={true}
        position="top-center"
        style= {{whiteSpace: "pre-line", width: "35em", wordBreak: "break-word"}}
      />
      <IntlProvider>
        <GlobalContext.Provider value={context}>
          <QueryClientProvider client={queryClient}>
            <React.StrictMode>
              <Router basename="/">
                <Header />
                <main id="main" role="main" className="container-fluid d-flex flex-column">
                  <div className="flex-grow-1">
                    <Authentication>
                      <Routes>
                        <Route path="/" Component={Home} />
                        <Route path="*" Component={PageNotFound} />
                      </Routes>
                    </Authentication>
                  </div>
                  <HelpCenter />
                </main>
                <Footer />
              </Router>
            </React.StrictMode>
          </QueryClientProvider>
        </GlobalContext.Provider>
      </IntlProvider>
    </ErrorCatch>
  );
};

export default App;
